import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Collapse,
  Card,
  CardContent,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SearchBar from '../Components/SearchBar';
import {
  CircularProgress,
} from '@mui/material';
import baseUrl from '../config';


const StudentsList = ({ students, onStudentClick, selectedStudent, onScheduleClass }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [classDate, setClassDate] = useState('');
  const [classTime, setClassTime] = useState('');
  const [timezone, setTimezone] = useState('America/New_York');
  const timezones = ['America/New_York', 'America/Los_Angeles', 'Europe/London', 'Asia/Tokyo'];

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.length > 0 ? (
              students.map((studentData, index) => {
                const { student, classSchedules, startDate, endDate } = studentData;

                return (
                  <TableRow key={index}>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{new Date(startDate).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(endDate).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => onStudentClick(studentData)}
                      >
                        {selectedStudent === studentData ? 'Hide Details' : 'Show Details'}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setFormOpen(!formOpen);
                          onStudentClick(studentData); // Show student details if not already shown
                        }}
                      >
                        {formOpen ? 'Close Scheduling' : 'Schedule Class'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No students found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedStudent && (
        <Collapse in={Boolean(selectedStudent)} sx={{ mt: 2 }}>
          <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6">Student Details</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">
                <strong>Name:</strong> {selectedStudent.student.name}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {selectedStudent.student.email}
              </Typography>
              <Typography variant="body1">
                <strong>Start Date:</strong> {new Date(selectedStudent.startDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                <strong>End Date:</strong> {new Date(selectedStudent.endDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                <strong>Schedule:</strong>
                {selectedStudent.classSchedules.length > 0 ? (
                  <ul>
                    {selectedStudent.classSchedules.map((schedule, index) => (
                      <li key={index}>
                        <strong>Start Date:</strong> {new Date(schedule.startDate).toLocaleDateString()}<br />
                        <strong>End Date:</strong> {new Date(schedule.endDate).toLocaleDateString()}<br />
                        <strong>Time:</strong> {schedule.time}<br />
                        <strong>Join URL:</strong> {schedule.joinUrl ? <a href={schedule.joinUrl} target="_blank" rel="noopener noreferrer">{schedule.joinUrl}</a> : 'N/A'}
                      </li>
                    ))}
                  </ul>
                ) : (
                  'No classes scheduled'
                )}
              </Typography>
              {formOpen && (
                <Box mt={2}>
                  <Typography variant="h6">Schedule a New Class</Typography>
                  <TextField
                    label="Class Date"
                    type="date"
                    value={classDate}
                    onChange={(e) => setClassDate(e.target.value)}
                    sx={{ mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Class Time"
                    type="time"
                    value={classTime}
                    onChange={(e) => setClassTime(e.target.value)}
                    sx={{ mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Timezone</InputLabel>
                    <Select
                      value={timezone}
                      onChange={(e) => setTimezone(e.target.value)}
                    >
                      {timezones.map((tz) => (
                        <MenuItem key={tz} value={tz}>
                          {tz}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      onScheduleClass(selectedStudent.student._id, selectedStudent.courseId, classDate, classTime, timezone)
                    }
                  >
                    Schedule Class
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Collapse>
      )}
    </>
  );
};

// Fetch student data
const fetchCourseData = async () => {
  try {
    const response = await fetch(`${baseUrl}api/teachers/students`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error;
  }
};

// Schedule Class Function
const scheduleClass = async (studentId, courseId, classDate, classTime, timezone) => {
  try {
    const response = await fetch(`${baseUrl}api/teachers/schedule-class`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        studentId,
        courseId,
        classDate,
        classTime,
        timezone
      })
    });

    if (!response.ok) {
      throw new Error('Failed to schedule class');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error scheduling class:', error);
    throw error;
  }
};

const MyStudent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const courseData = await fetchCourseData();
        setStudents(courseData);
      } catch (error) {
        setError('An error occurred while fetching student data.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const handleStudentClick = (studentData) => {
    setSelectedStudent(selectedStudent === studentData ? null : studentData); // Toggle detail view
  };

  const handleScheduleClass = async (studentId, courseId, classDate, classTime, timezone) => {
    try {
      const result = await scheduleClass(studentId, courseId, classDate, classTime, timezone);
      alert('Class scheduled successfully! Zoom Link: ' + result.zoomLink);
      setSelectedStudent(null); // Close the details view after scheduling
    } catch (error) {
      alert('Failed to schedule class: ' + error.message);
    }
  };

  const filteredStudents = (students) =>
    students.filter((student) =>
      student.student.name.toLowerCase().includes(searchQuery) ||
      student.student.email.toLowerCase().includes(searchQuery)
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        backgroundColor: '#fff',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
        My Students
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Total Students Assigned: {students.length}
      </Typography>

      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }} style={{ alignItems: 'center' }}>
        <Box>
          <SearchBar onSearch={handleSearch} />
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Paper elevation={3} sx={{ p: 2, backgroundColor: '#fdd', color: '#a00', mb: 4 }}>
          <Typography variant="body1">{error}</Typography>
        </Paper>
      )}
      {!loading && !error && (
        <StudentsList
          students={filteredStudents(students)}
          onStudentClick={handleStudentClick}
          selectedStudent={selectedStudent}
          onScheduleClass={handleScheduleClass}
        />
      )}
    </Box>
  );
};

export default MyStudent;