import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  IconButton,Link
} from '@mui/material';
import SearchBar from '../Components/SearchBar';
import baseUrl from '../config';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Fetch course data
const fetchCourseData = async () => {
  try {
    const response = await fetch(`${baseUrl}api/teachers/assigned-courses`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error;
  }
};

const MyCourse = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const courseData = await fetchCourseData();
        setCourses(courseData.courses); // Assuming courseData.courses is the array of courses
        setCourseCount(courseData.courseCount); // Extract courseCount from the response
      } catch (error) {
        setError('An error occurred while fetching course data.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(selectedCourse === course ? null : course); // Toggle detail view
  };

  const filteredCourses = (courses) =>
    courses.filter((course) => course.title.toLowerCase().includes(searchQuery));

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Box mt={3}>
          <Typography variant="h4">My Courses</Typography>
          <Typography variant="h6" gutterBottom>
            Total Courses Assigned: {courseCount}
          </Typography>
        </Box>

        <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }} style={{ alignItems: 'center' }}>
          <Box></Box>
          <Box>
            <SearchBar onSearch={handleSearch} />
          </Box>
        </Box>

        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {!loading && !error && (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Course Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourses(courses).map((course) => (
                  <TableRow key={course._id}>
                    <TableCell>{course.title}</TableCell>
                    <TableCell>{course.description}</TableCell>
                    <TableCell>{course.duration} mins</TableCell>
                    <TableCell>{course.courseType}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleCourseClick(course)}>
                        {selectedCourse === course ? 'Hide Details' : 'Show Details'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {selectedCourse && (
          <Collapse in={Boolean(selectedCourse)} sx={{ mt: 2 }}>
            <Box sx={{ p: 2, border: '1px solid', borderRadius: 1 }}>
              <Typography variant="h6">Course Details</Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Title:</strong> {selectedCourse.title}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Description:</strong> {selectedCourse.description}
              </Typography>Link
              <Typography variant="body1" gutterBottom>
                <strong>Duration (Per Class):</strong> {selectedCourse.duration}  minutes
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Course Type:</strong> {selectedCourse.courseType}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Booked Classes:</strong> {selectedCourse.bookedClasses}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Class Schedule:</strong> {selectedCourse.classSchedule.map(schedule => (
                  <div key={schedule._id}>
                    {new Date(schedule.date).toLocaleDateString()} at {schedule.time}
                  </div>
                ))}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Chapters:</strong>
                <ul>
                  {selectedCourse.chapters.map(chapter => (
                    <li key={chapter._id}>
                      <strong>{chapter.title}:</strong> <Link href={chapter.description} target="_blank" rel="noopener noreferrer">
  {chapter.description}
</Link>
                    </li>
                  ))}
                </ul>
              </Typography>
            </Box>
          </Collapse>
        )}
      </Box>
    </Box>
  );
};

export default MyCourse;
