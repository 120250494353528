import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Add axios or use fetch for HTTP requests
import { Box, Card, CardContent, Typography, Button, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import ReportIcon from '@mui/icons-material/Report';
import DownloadIcon from '@mui/icons-material/Download';
import baseUrl from '../config';

const MyEarnings = () => {
    const [earnings, setEarnings] = useState({
        totalEarnings: 2000,
        totalDeductions: 1000,
        netEarnings: 1900,
        paidClassesEarnings: 1600,
        demoClassesEarnings: 2000,
        incentives: 100,
        deductions: 1000
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
console.log("token" ,localStorage.getItem('token'));

    // useEffect(() => {
    //     const fetchEarnings = async () => {
    //         try {
    //             const response = await axios.get(baseUrl+'api/teachers/earnings', {
    //                 method: 'GET',
    //                 headers: {
    //                   'Authorization': `Bearer ${localStorage.getItem('token')}`
    //                 }
    //               }); // Adjust URL if needed
    //             setEarnings(response.data);
    //         } catch (error) {
    //             setError(error.message);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchEarnings();
    // }, []);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom>My Earnings</Typography>
            <Typography variant="subtitle1" color="textSecondary">August 2024</Typography>

            <Card sx={{ marginTop: 2, padding: 2, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ background: 'linear-gradient(135deg, #6C63FF 30%, #00008B 90%)', padding: 3, borderRadius: 2, color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4">₹ {earnings.totalEarnings}</Typography>
                        <Typography variant="body1">Total Earnings</Typography>
                        <Typography variant="body2">Deductions ₹ {earnings.totalDeductions}</Typography>
                    </Box>
                </Box>
                <IconButton aria-label="more options" sx={{ marginLeft: 2 }}>
                    <ReportIcon />
                </IconButton>
            </Card>

            <List sx={{ marginTop: 2 }}>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <AccountBalanceWalletIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Paid Classes" secondary="49 Classes" />
                    <Typography variant="body1">₹ {earnings.paidClassesEarnings}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <SchoolIcon color="info" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Demo Classes" secondary="2 Classes" />
                    <Typography variant="body1">₹ {earnings.demoClassesEarnings}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <EmojiEventsIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Incentives" secondary="3 Incentives" />
                    <Typography variant="body1">₹ {earnings.incentives}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <StarIcon color="warning" />
                    </ListItemIcon>
                    <ListItemText primary="Star Teacher Reward" secondary="Get 10% extra payout by becoming a Star Teacher in August." />
                    <Typography variant="body1">₹ 1000</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <ReportIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Deductions" secondary="2 Deductions" />
                    <Typography variant="body1">₹ {earnings.deductions}</Typography>
                </ListItem>
            </List>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
                <Button variant="outlined" startIcon={<DownloadIcon />}>Download Payslip</Button>
                <Button variant="contained" color="primary">Detailed Breakdown</Button>
            </Box>

            <Box sx={{ marginTop: 3, padding: 2, backgroundColor: '#e0ffe0', borderRadius: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" color="success.main">32% perfect classes this month</Typography>
                <Button size="small" color="info">What is a perfect class?</Button>
            </Box>
        </Box>
    );
};

export default MyEarnings;
