import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  CircularProgress,
  Link,
  Grid,
  Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import baseUrl from '../config';

// Custom styled components for tree lines and other UI elements
const TreeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: 5,
  paddingLeft: '15px',
  backgroundColor: theme.palette.background.paper,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '3px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const Line = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '5px',
  top: 10,
  width: '5px',
  backgroundColor: theme.palette.primary.light,
}));

const StudentCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const MyClassDetails = () => {
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [markAttendanceError, setMarkAttendanceError] = useState('');

  const getUpcomingClasses = async () => {
    try {
      const response = await axios.get(baseUrl + 'api/teachers/upcoming-classes', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching upcoming classes:', error);
      throw error;
    }
  };

  const markAttendance = async (enrollmentId, classScheduleId) => {
    try {
      const response = await axios.post(
        baseUrl + `api/teachers/mark-attendance/${enrollmentId}/${classScheduleId}`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      console.log('Attendance Marked Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error marking attendance:', error);
      throw error;
    }
  };

  const fetchUpcomingClasses = async () => {
    try {
      const data = await getUpcomingClasses();
      setUpcomingClasses(data || []); // Adjust based on actual API response
    } catch (error) {
      setError('Failed to fetch upcoming classes');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingClasses();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) =>
      isExpanded
        ? [...prev, panel]
        : prev.filter((p) => p !== panel)
    );
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleMarkAttendance = async (enrollmentId, classScheduleId) => {
    try {
      await markAttendance(enrollmentId, classScheduleId);
      // Update the state to reflect the attendance mark
      setUpcomingClasses((prevClasses) =>
        prevClasses.map((course) => ({
          ...course,
          students: course.students.map((student) =>
            student.studentId === enrollmentId
              ? {
                  ...student,
                  totalAttendedClasses: student.totalAttendedClasses + 1
                }
              : student
          )
        }))
      );
      setMarkAttendanceError('');
    } catch (error) {
      setMarkAttendanceError('Failed to mark attendance');
      setOpenSnackbar(true);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      {/* Tabs */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="class-tabs"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Regular Classes" />
        <Tab label="Activity Classes" />
        <Tab label="Mock Assessment" />
      </Tabs>

      <Box display="flex" mt={2} flexDirection="column">
        {/* Render content based on selected tab */}
        {tabValue === 0 && (
          <Grid container spacing={3}>
            {/* Student Cards */}
            <Grid item xs={12} md={4} lg={3}>
              <Box>
                {upcomingClasses.length > 0 ? (
                  upcomingClasses.map((classInfo, index) => (
                    <StudentCard key={index} sx={{ mb: 2 }}>
             
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {classInfo.student.name || 'User Name'} 
                          <span style={{ color: 'red' }}>{classInfo.course.courseType}</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {classInfo.course.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Email: {classInfo.student.email}
                        </Typography>
                        <Box mt={2}>
                          {classInfo.upcomingClasses.length > 0 && classInfo.upcomingClasses.map((upcomingClass, classIndex) => (
                            <Box key={classIndex} mb={2}>
                              <Typography variant="body2" color="text.secondary">
                                Schedule: {new Date(upcomingClass.startDate).toLocaleDateString()} - {upcomingClass.time}
                              </Typography>
                            
                      <Typography variant="body2" color="text.secondary">
                        Total Classes: {upcomingClass.totalClasses}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Total Attended Classes: {upcomingClass.totalAttendedClasses}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Date of First Class: {/* Implement logic to get this */}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Next Class: {upcomingClass.nextClass?.time}
                      </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                href={upcomingClass.joinUrl}
                                target="_blank"
                                sx={{ mb: 1 }}
                              >
                                Join Class
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                onClick={() => handleMarkAttendance(classInfo.student.studentId, upcomingClass._id)}
                              >
                                Mark Attendance
                              </Button>
                        
                    <Button variant="outlined" color="primary" fullWidth sx={{ mt: 1 }}>
                      Raise An Issue
                    </Button>
                            </Box>

                          ))}
                        </Box>
                      </CardContent>
                    </StudentCard>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No upcoming classes
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Class Topics */}
            <Grid item xs={12} md={8} lg={9}>
              <Container>
                <Typography variant="h4" gutterBottom>
                  Courses and Chapters
                </Typography>
                {error && (
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={error}
                  />
                )}
                {markAttendanceError && (
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={markAttendanceError}
                  />
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Class Topics
                  </Typography>
                  <TreeContainer>
                    {upcomingClasses.map((classInfo, index) => (
                      <Accordion
                        key={index}
                        expanded={expanded.includes(classInfo.course.title)}
                        onChange={handleAccordionChange(classInfo.course.title)}
                        sx={{ mb: 2 }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="h6">{classInfo.course.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Line />
                          {classInfo.course.chapters.map((chapter) => (
                            <Accordion
                              key={chapter._id}
                              expanded={expanded.includes(chapter._id)}
                              onChange={handleAccordionChange(chapter._id)}
                              sx={{ mb: 1 }}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{chapter.title}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <Link href={chapter.description} target="_blank" rel="noopener noreferrer">
                                    {chapter.description}
                                  </Link>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </TreeContainer>
                </Box>
              </Container>
            </Grid>
          </Grid>
        )}

{tabValue === 1 && (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: 'calc(100vh - 50vh)', // Adjust based on header height
      textAlign: 'center' 
    }}
  >
    <Paper 
      elevation={3} 
      sx={{ 
        padding: 3, 
        maxWidth: 600, 
        width: '100%', 
        borderRadius: 2, 
        backgroundColor: 'background.paper' 
      }}
    >
      <Typography variant="h4" gutterBottom>
        Activity Classes
      </Typography>
      <Typography variant="body1" paragraph>
        Here you will find information about upcoming activity classes. 
     Stay tuned for updates!
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 2 }}
      >
        Learn More
      </Button>
    </Paper>
  </Box>
)}


        {tabValue === 2 && (
         <Box 
         sx={{ 
           display: 'flex', 
           flexDirection: 'column', 
           alignItems: 'center', 
           justifyContent: 'center', 
           height: 'calc(100vh - 50vh)', // Adjust based on header height
           textAlign: 'center' 
         }}
       >
         <Paper 
           elevation={3} 
           sx={{ 
             padding: 3, 
             maxWidth: 600, 
             width: '100%', 
             borderRadius: 2, 
             backgroundColor: 'background.paper' 
           }}
         >
           <Typography variant="h4" gutterBottom>
           Mock Assessment           </Typography>
           <Typography variant="body1" paragraph>
             Here you will find information about upcoming Mock Assessment. 
          Stay tuned for updates!
           </Typography>
           <Button 
             variant="contained" 
             color="primary" 
             sx={{ mt: 2 }}
           >
             Learn More
           </Button>
         </Paper>
       </Box>
        )}
      </Box>
    </Container>
  );
};

export default MyClassDetails;
