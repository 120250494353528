import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,IconButton,Menu,TextField,MenuItem,
} from '@mui/material';
import axios from 'axios';
import baseUrl from '../config';
import {AbcOutlined} from '@mui/icons-material';

// Helper component to manage tab content
const TabPanel = ({ value, index, children }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    sx={{ p: 3 }}
  >
    {value === index && <Typography>{children}</Typography>}
  </Box>
);

const DemoRequestPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const teacher = JSON.parse(localStorage.getItem('user'));
  const [anchorEl, setAnchorEl] = useState(false);

  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  useEffect(() => {
    const fetchPendingRequests = async () => {
      try {
        const token = getAuthToken();
        const response = await axios.get(baseUrl + 'api/teachers/availableDemobooks', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPendingRequests(response.data);
      } catch (error) {
        console.error('Error fetching pending requests:', error);
      }
    };

    const fetchAcceptedRequests = async () => {
      try {
        const token = getAuthToken();
        const response = await axios.get(baseUrl + 'api/teachers/meetings', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setAcceptedRequests(response.data);
      } catch (error) {
        console.error('Error fetching accepted requests:', error);
      }
    };

    fetchPendingRequests();
    fetchAcceptedRequests();
  }, []);

  const handleAccept = async () => {
    try {
      const token = getAuthToken();
      await axios.post(baseUrl + `api/teachers/demoRequestAccept/${selectedRequest._id}`, { teacherId: teacher.id }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPendingRequests(prev => prev.filter(request => request._id !== selectedRequest._id));
      setAcceptedRequests(prev => [...prev, { ...selectedRequest, status: 'accepted' }]);
      handleCloseDialog();
    } catch (error) {
      console.error('Error accepting request:', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRequest(null);
  };

  

  const handleCloseFeedbackDialog = () => {
    setDialogOpen(false);
  };

  const handleDropdownOpen =()=>
  {
    setDialogOpen(true)
  }

  const handleDropdownClose = () => {
    setDialogOpen(false);

  }

  const handleSubmitFeedback=()=>{
    setDialogOpen(false);

  }

  
  const   handleFeedbackChange =()=>{
    
  }

  return (
    <Box>
      <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
        <Tab label="Pending Requests" />
        <Tab label="Accepted Requests" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {pendingRequests.length === 0 ? (
          <Typography>No demo requests yet.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingRequests.map(request => (
                  <TableRow key={request._id}>
                    <TableCell>{request.childName}</TableCell>
                    <TableCell>{request.grade}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleTimeString()}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedRequest(request);
                          setDialogOpen(true);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
     
          <TabPanel value={tabIndex} index={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Zoom Link</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedRequests.map(request => (
                <TableRow key={request._id}>
                  <TableCell>{request.childName}</TableCell>
                  <TableCell>{request.grade}</TableCell>
                  <TableCell>{new Date(request.date).toLocaleTimeString()}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      href={request.demoLink[0]?.joinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join Meeting
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleDropdownOpen(e, request)}>
                      <AbcOutlined />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem     onClick={() => {
                                                   setDialogOpen(true);
                                                   handleDropdownClose('conducted')}}>Conducted</MenuItem>
                      <MenuItem onClick={() => handleDropdownClose('notConducted')}>Not Conducted</MenuItem>
                      <MenuItem onClick={() => handleDropdownClose('reschedule')}>Reschedule</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      {/* Feedback Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseFeedbackDialog} fullWidth>
        <DialogTitle>Feedback Form</DialogTitle>
        <DialogContent>
          <TextField
            name="studentName"
            label="Student Name"
            fullWidth
            margin="normal"
            // value={feedback.studentName}
            onChange={handleFeedbackChange}
          />
          <TextField
            name="parentName"
            label="Parent Name"
            fullWidth
            margin="normal"
            // value={feedback.parentName}
            onChange={handleFeedbackChange}
          />
          <TextField
            name="kidsConfidence"
            label="Kids' Confidence"
            fullWidth
            margin="normal"
            // value={feedback.kidsConfidence}
            onChange={handleFeedbackChange}
          />
          <TextField
            name="financialCondition"
            label="Financial Condition"
            fullWidth
            margin="normal"
            // value={feedback.financialCondition}
            onChange={handleFeedbackChange}
          />
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mt: 2 }}
          >
            Upload Screenshot
            <input
              type="file"
              hidden
              // onChange={handleFileChange}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedbackDialog}>Cancel</Button>
          <Button onClick={handleSubmitFeedback} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent>
          {selectedRequest && (
            <Box>
              <Typography variant="h6">Student Name: {selectedRequest.childName}</Typography>
              <Typography variant="body1">Grade: {selectedRequest.grade}</Typography>
              <Typography variant="body1">Date: {new Date(selectedRequest.date).toLocaleDateString()}</Typography>
              <Typography variant="body1">Time: {new Date(selectedRequest.date).toLocaleTimeString()}</Typography>
              <Typography variant="body1">Description: {selectedRequest.description || 'No description available'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button onClick={handleAccept} color="primary">Accept Request</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DemoRequestPage;
