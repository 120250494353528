import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  CircularProgress,
  Grid,
  Tabs,
  Tab,
  Paper,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert'; // Import MuiAlert
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import baseUrl from '../config';
import WarningIcon from '@mui/icons-material/Warning';

// Custom styled components
const TreeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: 5,
  paddingLeft: '15px',
  backgroundColor: theme.palette.background.paper,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '3px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StudentCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const MyClassDetails = () => {
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Added severity state
  const [tabValue, setTabValue] = useState(0);
  
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [attendanceParams, setAttendanceParams] = useState({});

  const getUpcomingClasses = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/teachers/upcoming-classes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching upcoming classes:', error);
      throw error;
    }
  };

  const fetchUpcomingClasses = async () => {
    try {
      const data = await getUpcomingClasses();
      setUpcomingClasses(data || []);
    } catch (error) {
      setError('Failed to fetch upcoming classes');
      setSnackbarSeverity('error'); // Set severity to error
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingClasses();
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenConfirmDialog = (enrollmentId, classScheduleId) => {
    setAttendanceParams({ enrollmentId, classScheduleId });
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setAttendanceParams({});
  };

  const confirmMarkAttendance = async () => {
    const { enrollmentId } = attendanceParams;
    try {
      const response = await axios.post(`${baseUrl}api/teachers/mark-attendance/${enrollmentId}`, {
        studentId: localStorage.getItem('studentId'),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setSnackbarSeverity('success'); // Set severity to success
      setError(response.data.message || 'Attendance marked successfully');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error marking attendance:', error?.response?.data?.message);
      setSnackbarSeverity('error'); // Set severity to error
      setError(error?.response?.data?.message);
      setOpenSnackbar(true);
    } finally {
      handleCloseConfirmDialog();
    }
  };

  if (loading) {
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="class-tabs"
          sx={{ borderBottom: 1, borderColor: '#09135c', overflowX: 'auto' }}
        >
          <Tab 
            label="Regular Classes" 
            sx={{ 
              color: tabValue === 0 ? '#09135c' : 'text.secondary', 
              '&:hover': { color: '#09135c' } 
            }} 
          />
          <Tab 
            label="Activity Classes" 
            sx={{ 
              color: tabValue === 1 ? '#09135c' : 'text.secondary', 
              '&:hover': { color: '#09135c' } 
            }} 
          />
          <Tab 
            label="Mock Assessment" 
            sx={{ 
              color: tabValue === 2 ? '#09135c' : 'text.secondary', 
              '&:hover': { color: '#09135c' } 
            }} 
          />
        </Tabs>

        <Box display="flex" mt={2} flexDirection="column">
          {tabValue === 0 && (
            <Grid container spacing={3}>
              {upcomingClasses.length > 0 ? (
                upcomingClasses.map((classInfo) => (
                  <Grid item xs={12} key={classInfo.enrollmentId}>
                    <StudentCard sx={{ mb: 2 }}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                              {classInfo.student.name || 'User Name'}{' '}
                              <span style={{ color: 'red' }}>
                                ({classInfo.course.courseType})
                              </span>
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              {classInfo.course.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Email: {classInfo.student.email}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              Schedule: {new Date(classInfo.upcomingClasses[0]?.startDate).toLocaleDateString()} - {classInfo.upcomingClasses[0]?.time}
                            </Typography>

                            {classInfo.upcomingClasses.map((upcomingClass) => (
                              <Box key={upcomingClass.classScheduleId} mb={2}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  href={upcomingClass.joinUrl}
                                  target="_blank"
                                  sx={{ bgcolor: '#09135c', color: 'white', mb: 1 }}>
                                  Join Class
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  fullWidth
                                  onClick={() => handleOpenConfirmDialog(classInfo.enrollmentId, upcomingClass.classScheduleId)}
                                >
                                  Mark Attendance
                                </Button>
                              </Box>
                            ))}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <TreeContainer>
                              <Typography variant="h6" gutterBottom>
                                Class Topics
                              </Typography>
                              <Accordion
                                expanded={expanded.includes(classInfo.course.title)}
                                onChange={handleAccordionChange(classInfo.course.title)}
                                sx={{ mb: 2 }}
                              >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Typography variant="h6">{classInfo.course.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                  }}
                                >
                                  {classInfo.course.chapters.map((chapter) => (
                                    <Accordion
                                      key={chapter._id}
                                      expanded={expanded.includes(chapter._id)}
                                      onChange={handleAccordionChange(chapter._id)}
                                      sx={{ mb: 1 }}
                                    >
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{chapter.title}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails
                                        sx={{
                                          maxHeight: '150px',
                                          overflowY: 'auto',
                                        }}
                                      >
                                        <Link href={chapter.description} target="_blank" rel="noopener noreferrer">
                                          {chapter.description}
                                        </Link>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            </TreeContainer>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </StudentCard>
                  </Grid>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No upcoming classes
                </Typography>
              )}
            </Grid>
          )}
          {tabValue === 1 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 50vh)', textAlign: 'center' }}>
              <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, width: '100%', borderRadius: 2, backgroundColor: 'background.paper' }}>
                <Typography variant="h4" gutterBottom>
                  Activity Classes
                </Typography>
                <Typography variant="body1" paragraph>
                  Here you will find information about upcoming activity classes. 
                  Stay tuned for updates!
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                  Learn More
                </Button>
              </Paper>
            </Box>
          )}

          {tabValue === 2 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 50vh)', textAlign: 'center' }}>
              <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, width: '100%', borderRadius: 2, backgroundColor: 'background.paper' }}>
                <Typography variant="h4" gutterBottom>
                  Mock Assessments
                </Typography>
                <Typography variant="body1" paragraph>
                  Information about mock assessments will be available here.
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                  Check Assessments
                </Button>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        maxWidth="sm"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <DialogTitle sx={{ bgcolor: '#09135c', color: 'white', textAlign: 'center', py: 2 }}>
          Confirm Attendance
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <WarningIcon sx={{ color: 'warning.main', mr: 1 }} fontSize="large" />
            <Typography variant="h6">
              Confirm Your Attendance
            </Typography>
          </Box>
          <Typography variant="body1">
            Are you sure you want to mark attendance for this class?
          </Typography>
          <Typography variant="body2" color="warning.main" sx={{ mt: 2 }}>
            Please ensure that you are genuinely attending the class. 
            Marking attendance falsely may result in account penalties.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button 
            onClick={handleCloseConfirmDialog} 
            color="inherit" 
            variant="outlined" 
            sx={{ borderRadius: '8px' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmMarkAttendance} 
            sx={{ bgcolor: '#09135c', color: 'white', borderRadius: '8px' }} 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity} // Use severity state
          sx={{ width: '100%' }}
        >
          {error}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default MyClassDetails;
