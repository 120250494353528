import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,Paper
} from '@mui/material';
import SideCard from './SideCard';
import MyClassDetails from '../Components/StoryTelling';
import StarTeacherSection from '../Components/StarTeacherSection';
import SearchBar from '../Components/SearchBar';

// Mock function to simulate fetching class schedules
const fetchClassSchedules = async () => {
  // Replace this with your actual data fetching logic
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([]); // Return an empty array to simulate no class schedules
    }, 1000);
  });
};

const DashboardPage = () => {
  const [classSchedules, setClassSchedules] = useState([]);
  const [teacherData, setTeacherData] = useState({});
  const [achievementData, setAchievementData] = useState({});
  // const [achievements, setAchievements] = useState([]);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Fetch teacher data from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setTeacherData({
        name: user.name,
        id: user.uniqueId,
        avatar: 'path/to/avatar.jpg', // Placeholder, or adjust if needed
      });
    }

    // Fetch achievement data from localStorage
    // const achievementsData = JSON.parse(localStorage.getItem('achievements')) || [];
    // setAchievements(achievementsData);

    // // Placeholder for achievementData (you can adjust as needed)
    // setAchievementData({
    //   date: 'August 2024',
    //   progress: '2/4 Achieved',
    // });
  }, []);


  useEffect(() => {
    // Fetch class schedules when component mounts
    const getClassSchedules = async () => {
      const schedules = await fetchClassSchedules();
      setClassSchedules(schedules);
    };

    getClassSchedules();


  }, []);




  return (
    <Box 
      display="flex"
      height= '100%'
      sx={{ backgroundColor: '#f5f5f5' }}

      flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
    >
      
  
      {!classSchedules.length === 0 ? (
             <Box
            // Optional padding
           >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="h6" gutterBottom>
            No class schedules available.
          </Typography>
        </Box>
        </Box>
      ) : (
        <>
         <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 2,

      }}
    >
    
    <Paper elevation={2} sx={{ p: 2, mb: 3, background: '#b92323', borderRadius: '10px' }}>
    <Box sx={{ mb: 3 }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
      👋 Hello {teacherData?.name}
    </Typography>
    <Typography variant="subtitle" sx={{ color: '#d6d1d1' }}>
    Unlocking Potential, One Student at a Time    </Typography>
  </Box>
</Paper>
      <MyClassDetails />

      </Box>
          <SideCard />
        </>
      )}
    </Box>
  );
};

export default DashboardPage;
