import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Avatar, Button, Chip, Grid, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Star, Flag, Edit } from '@mui/icons-material';
import baseUrl from '../config';

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [bio, setBio] = useState('');
  const [reviews, setReviews] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    starRating: 0,
    languages: [],
    skills: [],
    bio: ''
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
console.log(profile,"profile");

  useEffect(() => {
    // Fetch profile data
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(baseUrl + 'api/teachers/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setProfile(data);
        setSkills(data.skills || []);
        setBio(data.bio || '');
        setReviews(data.reviews || []);
        setFormData({
          name: data.name,
          avatar: data.avatar,
          starRating: data.starRating,
          languages: data.languages || [],
          skills: data.skills || [],
          bio: data.bio
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(baseUrl + 'api/teachers/profile', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setProfile(data);
      setEditModalOpen(false);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  if (!profile) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Profile and Skills Sections */}
        <Grid item xs={12} sm={6} md={3}>
          {/* Profile Card */}
          <Card sx={{ borderRadius: 3, boxShadow: 3, mb: 4 }}>
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar
                  src={formData.avatar || "/path/to/avatar.jpg"} // Replace with actual avatar path
                  sx={{ width: 80, height: 80, mb: 2, }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 700,color:'#D81B60' }} >
                    {formData.name}
                  </Typography>
                  <IconButton sx={{ ml: 1 }} onClick={() => setEditModalOpen(true)}>
                    <Edit />
                  </IconButton>
                </Box>
                <Typography variant="body2" sx={{ fontWeight: 100,color:'#D81B60' }}>
                  Educator Id: {profile.uniqueId}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Star
                      key={index}
                      sx={{ color: index < formData.starRating ? '#FFC107' : '#e0e0e0' }}
                    />
                  ))}
                  <Typography variant="h6" sx={{ ml: 1, fontWeight: 700 }}>
                    {formData.starRating}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {reviews.length} rating{reviews.length !== 1 ? 's' : ''}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Flag sx={{ fontSize: 20, mr: 1 }} />
                  <Typography variant="body1">{profile.country}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                  {formData.languages.map((language) => (
                    <Chip sx={{color:'#D81B60'}} key={language} label={language} />
                  ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {profile.classesTaken}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Classes Taken
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {profile.studentsTaught}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Students Taught
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
                  <Button variant="text" sx={{ color: '#D81B60' }}>
                    Share profile
                  </Button>
                <div></div>
                </Box>
              </Box>
            </CardContent>
          </Card>

          {/* Skills Section */}
          <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 2 ,color:'#D81B60'}}>
                My skills
              </Typography>
              <Grid container spacing={1} justifyContent="center">
                {formData.skills.map((skill) => (
                  <Grid item key={skill}>
                    <Chip sx={{color:'#D81B60'}} label={skill} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* About Me and Review Sections */}
        <Grid item xs={12} sm={6} md={8}>
          {/* About Me Section */}
          <Box sx={{ borderRadius: 3, boxShadow: 3, p: 3, backgroundColor: '#FFF', mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 700, mb: 2,color:'#D81B60' }}>
              About Me
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {formData.bio}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <img
                src="/path/to/illustration.jpg" // Replace with actual illustration path
                alt="Illustration"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Box>
          </Box>

          {/* Review Section */}
          <Box sx={{ textAlign: 'center', borderRadius: 3, boxShadow: 3, p: 3, backgroundColor: '#FFF' }}>
            <Typography variant="h6" sx={{ fontWeight: 700, mb: 2,color:'#D81B60' }}>
              Reviews
            </Typography>
            {reviews.length ? (
              reviews.map((review) => (
                <Box key={review._id} sx={{ mb: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {review.studentName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {review.review}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography>No reviews yet.</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Profile Edit Modal */}
      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Avatar URL"
              name="avatar"
              value={formData.avatar}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Star Rating"
              name="starRating"
              type="number"
              value={formData.starRating}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Languages (comma separated)"
              name="languages"
              value={formData.languages.join(', ')}
              onChange={(e) => setFormData({ ...formData, languages: e.target.value.split(',').map(l => l.trim()) })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Skills (comma separated)"
              name="skills"
              value={formData.skills.join(', ')}
              onChange={(e) => setFormData({ ...formData, skills: e.target.value.split(',').map(s => s.trim()) })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="About Me"
              name="aboutMe"
              value={formData.bio}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <DialogActions>
              <Button onClick={() => setEditModalOpen(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Update Profile
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;
